import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../styles/AnimatedIntro.css';
import logo from '../assets/logo/Logo.webp';
import { useMediaQuery, useTheme } from "@mui/material";
const introVariants = {
  hidden: { x: '-100%' },
  visible: { x: '0%', transition: { duration: 1 } },
  exit: { x: '-100%', transition: { duration: 1 } },
};

const logoVariants = {
  hidden: { scale: 0.5, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition: { duration: 1, delay: 0.5 } },
};

const AnimatedIntro: React.FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const theme = useTheme();
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (isAnimatingOut) {
      const timer = setTimeout(() => {
        onComplete();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isAnimatingOut, onComplete]);

  return (
    <motion.div
      className="introContainer"
      initial="hidden"
      animate={isAnimatingOut ? 'exit' : 'visible'}
      variants={introVariants}
      onAnimationComplete={() => {
        if (!isAnimatingOut) {
          setTimeout(() => setIsAnimatingOut(true), 2000);
        }
      }}
    >
      <motion.div
        className="logoContainer"
        initial="hidden"
        animate="visible"
        variants={logoVariants}
      >
        <img src={logo} alt="Logo" className="introLogo" style={{ maxHeight: isMobileOrTablet ? '75%' : '35%', maxWidth: isMobileOrTablet ? '75%' : '35%' }} />
      </motion.div>
    </motion.div>
  );
};

export default AnimatedIntro;
