import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import logo from "../../assets/logo/Logo.webp";

const Header: React.FC = () => {
  const [headerHeight, setHeaderHeight] = useState(100);
  const [ticking, setTicking] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isUserScrolling, setIsUserScrolling] = useState(true);

  const menuItems = useMemo(() => ['INICIO', 'SERVICIOS', 'ENCUÉNTRANOS', 'CONTACTO'], []);

  const handleScroll = useCallback(() => {
    const offset = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const height = Math.max(80, 100 - offset * 0.1);
        setHeaderHeight(height);
        setTicking(false);
      });
      setTicking(true);
    }
  }, [ticking]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleChange = (index: number, section: string) => {
    setIsUserScrolling(false);
    setSelectedIndex(index);
    scroll.scrollTo(section, { duration: 400, delay: 0, smooth: 'easeInOutQuart' });
    setDrawerOpen(false);

    setTimeout(() => {
      setIsUserScrolling(true);
    }, 450);
  };

  const handleSectionChange = useCallback(() => {
    if (isUserScrolling) {
      const sections = menuItems.map(item => document.getElementById(item.toLowerCase()));
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      const selectedIndex = sections.findIndex(section => {
        if (section) {
          const { offsetTop, offsetHeight } = section;
          return scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight;
        }
        return false;
      });

      if (selectedIndex !== -1) setSelectedIndex(selectedIndex);
    }
  }, [isUserScrolling, menuItems]);

  useEffect(() => {
    window.addEventListener('scroll', handleSectionChange);
    return () => {
      window.removeEventListener('scroll', handleSectionChange);
    };
  }, [handleSectionChange, isUserScrolling]);

  return (
    <div className="headerContainer">
      <AppBar position="static" className="header" style={{ height: `${headerHeight}px`, transition: 'height 0.3s ease' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img src={logo} alt="Logo" className="logo"
              style={{ height: `${headerHeight - 30}px`, transition: 'height 0.3s ease' }}
              onClick={() => handleChange(0, 'inicio')} loading="lazy" />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <img src={logo} alt="Logo" className="logo"
              style={{ height: `${headerHeight * 0.6}px`, transition: 'height 0.3s ease' }}
              onClick={() => handleChange(0, 'inicio')} loading="lazy" />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className="navMenu">
              {menuItems.map((item, index) => (
                <ScrollLink
                  key={index}
                  to={item.toLowerCase()}
                  smooth={true}
                  duration={400}
                  className={`navItem ${selectedIndex === index ? "selected" : ""}`}
                  style={{ fontSize: "15px", fontWeight: selectedIndex === index ? "bold" : "normal", color: selectedIndex === index ? "#d71d33" : "inherit" }}
                  onClick={() => handleChange(index, item.toLowerCase())}
                >
                  {item}
                </ScrollLink>
              ))}
            </div>
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon sx={{ color: '#d71d33', opacity: drawerOpen ? 0 : 1 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{ sx: { backgroundColor: 'rgb(33, 32, 30)', color: 'white' } }}>
        <Box sx={{ width: 220, paddingTop: '40px' }}>
          <List>
            {menuItems.map((item, index) => (
              <ScrollLink
                key={index}
                to={item.toLowerCase()}
                smooth={true}
                duration={400}
                onClick={() => handleChange(index, item.toLowerCase())}
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <ListItem
                  selected={selectedIndex === index}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' }
                    }
                  }}>
                  <ListItemText
                    primary={<Typography
                      variant="body1"
                      style={{ fontSize: "17px", fontWeight: selectedIndex === index ? "bold" : "normal", color: selectedIndex === index ? "#d71d33" : "#ffffff" }}>
                      {item}
                    </Typography>}
                  />
                </ListItem>
              </ScrollLink>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default Header;