import React from "react";
import { Fade } from "react-awesome-reveal";
import Catering from "./Catering.tsx";
import { Ipages } from "../../../Models/IPages.ts";

const SobreNosotros: React.FC<Ipages> = ({ colorText }) => {
  return (
    <Fade triggerOnce delay={200}>
      <Catering  colorText={colorText}/>
    </Fade>
  );
};

export default SobreNosotros;
