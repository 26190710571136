import React from 'react';
import { Fade } from 'react-awesome-reveal';
import AboutUs from './Location.tsx';
import { Ipages } from '../../../Models/IPages.ts';

const Inicio: React.FC<Ipages> = ({ colorText }) => {
    return (
        <Fade triggerOnce delay={200}>
            <AboutUs colorText={colorText}/>
        </Fade>
    );
};

export default Inicio;