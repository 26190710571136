import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Services from './Services.tsx';
import { Ipages } from "../../../Models/IPages.ts";

const ServiceInit: React.FC<Ipages> = ({ colorText }) => {
    return (
        <Fade triggerOnce delay={200}>
            <Services colorText={colorText}/>
        </Fade>
    );
};

export default ServiceInit;