import React from 'react';
import { Box, Typography, Modal, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface PrivacyPolicyModalProps {
    open: boolean;
    handleClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ open, handleClose }) => {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="privacy-policy-title"
            aria-describedby="privacy-policy-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobileOrTablet ? '90%' : 1200,
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
            }}>

                {/* Cabecera del modal */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: 'primary.main',
                    color: 'white',
                    p: 2,
                }}>
                    <Typography id="privacy-policy-title" variant="h6" component="h2">
                        Políticas de Privacidad
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Contenido del modal */}
                <Box sx={{ p: 3, flexGrow: 1 }}>
                    <Typography id="privacy-policy-description">
                        En Sushigi, estamos comprometidos con la protección de tus datos personales y con la transparencia en el tratamiento de la información que proporcionas. A continuación, te detallamos nuestra política de privacidad. Sushigi actua bajo la supervision y la  experiencia de Terra Sushi.

                        <br /><br />

                        <strong>Responsable del Tratamiento</strong>
                        <br />
                        TERRASUYI S.L.
                        <br />
                        CIF: B-88027701
                        <br />
                        Dirección: C/ Juan Carlos I, nº 13, local 19. 28806 Alcaá de Henares (Madrid)
                        <br />
                        Email: sushigi.catering@gmail.com

                        <br /><br />

                        <strong>Finalidad del Tratamiento</strong>
                        <br />
                        Los datos personales que recopilamos serán utilizados para:
                        <ul>
                            <li>Gestionar los servicios de catering solicitados por el usuario.</li>
                            <li>Responder a consultas o solicitudes realizadas a través de nuestro formulario de contacto.</li>
                        </ul>

                        <br />

                        <strong>Base Legal para el Tratamiento de Datos</strong>
                        <br />
                        El tratamiento de los datos personales se basa en:
                        <ul>
                            <li>El consentimiento del usuario, cuando lo haya proporcionado voluntariamente.</li>
                        </ul>
                        <br />

                        <strong>Conservación de los Datos</strong>
                        <br />
                        Conservaremos tus datos personales durante el tiempo necesario para cumplir con los fines para los que fueron recopilados, así como para cumplir con las obligaciones legales aplicables.
                        <br /><br />

                        <strong>Derechos de los Usuarios</strong>
                        <br />
                        Tienes derecho a:
                        <ul>
                            <li>Solicitar la limitación u oposición al tratamiento de tus datos.</li>
                        </ul>
                        Para ejercer tus derechos, puedes contactar con nosotros en el email proporcionado.
                        <br /><br />

                        <strong>Destinatarios de los Datos</strong>
                        <br />
                        No compartiremos tus datos personales con terceros, salvo cuando sea necesario para cumplir con la ley.

                        <br /><br />

                        <strong>Seguridad de los Datos</strong>
                        <br />
                        En Sushigi nos comprometemos a proteger tus datos personales mediante medidas de seguridad técnicas y organizativas para prevenir accesos no autorizados o el uso indebido de la información.

                        <br /><br />

                        <strong>Cambios en la Política de Privacidad</strong>
                        <br />
                        Nos reservamos el derecho a modificar la presente política de privacidad para adaptarla a cambios legislativos o mejoras en nuestros procesos. Te informaremos de cualquier cambio relevante.
                    </Typography>
                </Box>

                {/* Footer del modal */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: 'grey.100',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                }}>
                    <Button onClick={handleClose} variant="contained" color="primary" style={{ minWidth: '25%' }}>
                        Cerrar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PrivacyPolicyModal;
