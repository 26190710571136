import React, { useRef } from "react";
import { Card, CardContent, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import Slider from "react-slick";
import empresa from "../../../assets/comida/comida1.webp";
import comida2 from "../../../assets/comida/comida2.webp";
import comida3 from "../../../assets/comida/comida3.webp";
import comida4 from "../../../assets/comida/comida4.webp";
import comida5 from "../../../assets/comida/comida5.webp";
import comida6 from "../../../assets/comida/comida9.jpg";
import comida7 from "../../../assets/comida/comida8.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Ipages } from "../../../Models/IPages";

const images = [empresa, comida2, comida3, comida4, comida5, comida6, comida7];

const Arrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#fa130d", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
};

const Catering: React.FC<Ipages> = ({ colorText }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const sliderRef = useRef<Slider>(null);

  const handleThumbnailClick = (index: number) => {
    setCurrentSlide(index);
    sliderRef.current?.slickGoTo(index);
  };

  const settings = {
    dots: false,
    dotsClass: "slick-dots custom-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentSlide(newIndex),
  };

  return (
    <Box sx={{ minHeight: 800, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card sx={{
        maxWidth: "100%", boxShadow: 'none', p: 2, borderRadius: "10px", backgroundColor: "transparent",
      }}>
        <Box sx={{ display: "flex", flexDirection: isMobileOrTablet ? "column" : "row", alignItems: "flex-end" }}>
          <Box sx={{ flex: 1 }}>
            <CardContent sx={{ padding: isMobileOrTablet ? "30px" : "10px" }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", textAlign: "left", color: colorText, marginTop: isMobileOrTablet ? '45px' : '30px' }}>
                SOBRE NOSOTROS
              </Typography>
              <Typography variant="body1" marginTop={4} sx={{ color: colorText, fontWeight: 500, textAlign: "left" }}>
                Bienvenido a nuestro catering de sushi, donde fusionamos tradición y modernidad con ingredientes frescos para una experiencia inolvidable.
              </Typography>
              <Typography variant="body1" marginTop={4} sx={{ color: colorText, fontWeight: 500, textAlign: "left" }}>
                Sushigi tiene un propuesta gastronómica basada en la variedad y calidad de todas nuestras materias primas, añadiendo un servicio diferente plasmado en cada uno de nuestros platos
              </Typography>
              <Typography variant="body1" marginTop={4} sx={{ color: colorText, fontWeight: 500, textAlign: "left" }}>
                Ofrece un viaje por toda la gastronomía japonesa a través de una fusión de tradición y modernidad en cada lugar y momento único
              </Typography>
              <Typography variant="body1" marginTop={4} sx={{ color: colorText, fontWeight: 500, textAlign: "left" }}>
                Especialistas en eventos corporativos y celebraciones privadas, ofrecemos sushi delicioso y visualmente impresionante.
              </Typography>
              <Typography variant="body1" marginTop={5} sx={{ color: colorText, fontWeight: 500, textAlign: "left" }}>
                Nos esforzamos por superar expectativas con un servicio excepcional desde el primer contacto hasta el final del evento.
              </Typography>
            </CardContent>
          </Box>
          <Box sx={{ flex: 1, p: 3, maxWidth: isDesktop ? '58%' : '-webkit-fill-available' }} className="sliderContainer">
            <Slider ref={sliderRef} {...settings}>
              {images.map((image, index) => (
                <Box key={index} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={image} alt={`Sushi ${index + 1}`} style={{ borderRadius: "5px", maxWidth: "100%", height: "auto", maxHeight: "400px" }} />
                </Box>
              ))}
            </Slider>
            <div className="thumbnailContainer">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`thumbnailWrapper ${currentSlide === index ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    src={image}
                    alt={`thumbnail-${index}`}
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "30%",
                      border: currentSlide === index ? "2px solid #d71d33" : "2px solid transparent",
                      transition: "border-color 0.3s",
                    }}
                  />
                </div>
              ))}
            </div>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Catering;