import React, { useState } from "react";
import { Box, Typography, IconButton, Container, Grid, Button } from "@mui/material";
import { Instagram } from '@mui/icons-material';
import PrivacyPolicyModal from './PrivacyPolicyModal.tsx'; // Importar el nuevo componente del modal

const Footer: React.FC = () => {
  // Estado para abrir y cerrar el modal
  const [open, setOpen] = useState(false);

  // Funciones para manejar la apertura y cierre del modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box p={3} sx={{ backgroundColor: '#1a1a1a', color: 'white' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom color={"rgb(255, 165, 0)"} fontWeight={600} fontSize={"20px"} textAlign={"left"}>
                Sushigi
              </Typography>
              <Typography variant="body2" fontSize={"16px"} textAlign={"left"}>
                Disfruta de los mejores platos con nosotros. Fusionamos tradición y modernidad para ofrecer una experiencia culinaria única.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom color={"rgb(255, 165, 0)"} fontWeight={600} fontSize={"20px"} textAlign={"left"}>
                Síguenos en
              </Typography>
              <IconButton href="https://www.instagram.com/_sushigi_/" target="_blank" color="inherit">
                <Instagram />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Botón para abrir el modal de políticas de privacidad */}
      <Box p={2} sx={{ backgroundColor: 'black', color: 'white' }}>
        <Typography align="center" fontSize={"16px"} textAlign={"center"}>
          <Button
            onClick={handleOpen}
            sx={{
              backgroundColor: 'transparent',
              color: 'white',
              border: '2px solid white',
              padding: '10px 20px',
              borderRadius: '50px',
              fontSize: '16px',
              fontWeight: 'bold',
              textTransform: 'none', // Para que el texto no esté en mayúsculas
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'white',
                color: 'black',
                border: '2px solid white',
              }
            }}
          >
            Políticas de Privacidad
          </Button>
        </Typography>
      </Box>


      <Box p={2} sx={{ backgroundColor: 'black', color: 'white' }}>
        <Typography align="center" fontSize={"16px"} textAlign={"center"}>
          Copyright © 2024 Sushigi. Todos los derechos reservados.
        </Typography>
      </Box>

      {/* Usar el componente PrivacyPolicyModal */}
      <PrivacyPolicyModal open={open} handleClose={handleClose} />
    </>
  );
};

export default Footer;
