import * as React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

interface IServiceCardProps {
  image: string;
  title: string;
  description: string;
  colorText: string;
}

export const ServiceCard: React.FC<IServiceCardProps> = ({ image, title, description, colorText }) => {
  return (
    <Card sx={{
      maxWidth: 600,
      maxHeight: 360,
      margin: 'auto',
      boxShadow: 3,
      background: 'transparent',
      transition: 'transform 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    }}>
      <CardMedia
        component="img"
        height="250"
        image={image}
        alt={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" style={{ color: '#ffa500', fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" style={{ color: colorText }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
