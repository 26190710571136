import React from 'react';
import { Fade } from 'react-awesome-reveal';
import ContactForm from './ContactForm.tsx';
import { Ipages } from '../../../Models/IPages.ts';

const Contact: React.FC<Ipages> = ({ colorText }) => {
    return (
        <Fade triggerOnce delay={200}>
            <ContactForm colorText={colorText}/>
        </Fade>
    );
};

export default Contact;