import React from "react";
import { Box, Card, CardContent, Typography, Link, useMediaQuery, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Ipages } from "../../../Models/IPages";

const AboutUs: React.FC<Ipages> = ({ colorText }) => {

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{ minHeight: isMobileOrTablet ? 600 : 500, display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '15px' }}
    >
      <Card sx={{ boxShadow: 'none', p: 1, borderRadius: "0", backgroundColor: "transparent", }}>
        <Typography variant="h5" gutterBottom textAlign={"right"} marginRight={isMobileOrTablet ? 3 : 15} color={colorText} fontWeight={600}>
          QUIENES SOMOS
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3 }} >
          <Box sx={{ flex: 1 }}>
            <CardContent sx={{ padding: 1 }}>
              <Typography variant="body1" sx={{ color: colorText }} textAlign={"left"}>
                Nuestra historia comienza de la mano de Terra Sushi siendo pioneros de la comida japonesa en alcala de henares con más de 12 años de experiencia originando está nueva propuesta: Sushigi.
              </Typography>
              <Typography variant="body1" mt={4} sx={{ color: colorText }} textAlign={"left"}>
                Obteniendo así la sabiduría y el conocimiento para tratar  la materia prima de calidad a través de grandes expertos del sector.
              </Typography>
              <Typography variant="body1" mt={4} sx={{ color: colorText }} textAlign={"left"}>
                Desde el 2013 somos el mejor restaurante mejor valorado de alcala de henares.
                Elaborando comida japonesa de excelencia y frescura. Siempre aportando un hambiente y una decoracion creando una experiencia única para cada ocasion.
              </Typography>
            </CardContent>
          </Box>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start", justifyContent: 'center', maxWidth: isMobileOrTablet ? '100%' : '35%' }}>
            <Link href="https://www.google.com/maps?hl=es&gl=es&um=1&ie=UTF-8&fb=1&sa=X&ftid=0xd4249be38f482e9:0x3cb7fba694178023" target="_blank"
              sx={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)', backgroundColor: 'rgb(255, 165, 0)', padding: '8px', borderRadius: '8px', transition: 'background-color 0.3s', '&:hover': { backgroundColor: 'rgb(203 131 0)', } }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationOnIcon style={{ color: colorText, marginRight: 8 }} />
                <Typography variant="body1" sx={{ color: colorText }}>
                  Av/Juan Carlos I nº13, Local 19 Terra Sushi
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Card >
    </Box >
  );
};

export default AboutUs;
