import React, { useEffect, useState } from "react";
import Header from "./components/Header/Header.tsx";
import Footer from "./components/Footer/Footer.tsx";
import Empresa from "../src/components/pages/SobreNosotros/SobreNosotros.tsx";
import Contact from "./components/pages/Contacto/Contact.tsx";
import Inicio from "./components/pages/Location/Inicio.tsx";
import { Container, Box, IconButton } from "@mui/material";
import AnimatedIntro from "./components/AnimatedIntro.tsx";
import { AnimatePresence, motion } from "framer-motion";
import ServiceInit from "./components/pages/Servicios/ServiceInit.tsx";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const App: React.FC = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [introComplete, setIntroComplete] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
      setIntroComplete(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleIntroComplete = () => {
    setIntroComplete(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const backgroundColor = 'var(--background-header-color)'
  const colorText = "white"
  return (
    <div className="App">
      <AnimatePresence>
        {showIntro && <AnimatedIntro onComplete={handleIntroComplete} />}
      </AnimatePresence>
      {!showIntro && introComplete && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1 }}>
          <Header />
          <Box id="inicio" sx={{ background: `linear-gradient(to top, ${backgroundColor} 25%, white 250%)`, padding: "24px 24px 0px" }}>
            <Container maxWidth="lg" sx={{ padding: 0 }}>
              <Empresa colorText={colorText} />
            </Container>
          </Box>
          <Box id="servicios" sx={{ background: `linear-gradient(to bottom, ${backgroundColor}  25%, white 250%)`, padding: "10px 24px 0px" }}>
            <Container maxWidth="lg" sx={{ padding: 0 }}>
              <ServiceInit colorText={colorText} />
            </Container>
          </Box>
          <Box id="encuéntranos" sx={{ background: `linear-gradient(to top, ${backgroundColor}  25%, white 250%)`, padding: "24px 24px 10px" }}>
            <Container maxWidth="lg" sx={{ padding: 0 }}>
              <Inicio colorText={colorText} />
            </Container>
          </Box>
          <Box id="contacto" sx={{ background: `linear-gradient(to bottom, ${backgroundColor}  25%, white 250%)`, padding: "10px 24px 15px" }}>
            <Container maxWidth="lg" sx={{ padding: 0 }}>
              <Contact colorText={colorText} />
            </Container>
          </Box>
          <Footer />
        </motion.div>
      )}
      {showScrollButton && (
        <IconButton
          onClick={handleScrollToTop}
          sx={{
            opacity: 0.8,
            position: 'fixed',
            bottom: 40,
            right: 20,
            backgroundColor: 'rgb(255, 165, 0)',
            '&:hover': {
              backgroundColor: 'rgb(255, 140, 0)',
            },
            color: 'white',
            zIndex: 1000,
            width: 40,
            height: 40,
            borderRadius: '10px',
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
    </div>
  );
};

export default App;
