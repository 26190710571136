import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Box, Card, CardContent, Typography, TextField, Button, Grid, MenuItem, FormControl, InputLabel, Select, Alert, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import emailjs from 'emailjs-com';
import { AsYouType } from 'libphonenumber-js';
import { Ipages } from '../../../Models/IPages';

const initialValues = {
  nombre: '',
  telefono: '',
  tipoEvento: '',
  fechaEvento: null,
  numeroInvitados: '',
  mensaje: ''
};

const validationSchema = Yup.object().shape({
  nombre: Yup.string().required('El nombre es obligatorio'),
  telefono: Yup.string().required('El teléfono es obligatorio'),
  tipoEvento: Yup.string().required('El tipo de evento es obligatorio'),
  fechaEvento: Yup.date().required('La fecha del evento es requerida'),
  numeroInvitados: Yup.number().typeError('Debe ser un número').required('El número de invitados es obligatorio').positive('El número de invitados debe ser mayor que cero'),
});

const ContactForm: React.FC<Ipages> = ({ colorText }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const sendEmail = (values: any) => {
    const formattedPhone = new AsYouType().input(`+${values.telefono}`);

    const templateParams = {
      identificador: uuid().substring(0, 8),
      from_name: values.nombre,
      telefono: formattedPhone,
      tipoEvento: values.tipoEvento,
      fechaEvento: values.fechaEvento.format('DD/MM/YYYY'),
      numeroInvitados: values.numeroInvitados,
      mensaje: values.mensaje
    };

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    if (serviceID && templateID && userID) {
      emailjs.send(serviceID, templateID, templateParams, userID)
        .then((response) => {
          setSnackbarMessage('Email enviado correctamente!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        })
        .catch((error) => {
          setSnackbarMessage('Error al enviar el email. Inténtalo de nuevo.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarMessage('Variables de entorno no configuradas.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ paddingTop: 5.5 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card sx={{ marginTop: '30px', borderRadius: '10px', boxShadow: 3, padding: 2, background: 'linear-gradient(to right, var(--background-header-color) 0%, white 200%)' }}>
            <CardContent>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    sendEmail(values);
                    setSubmitting(false);
                    resetForm();
                  }}>
                  {({ isSubmitting, setFieldValue, values, errors, touched, isValid }) => (
                    <Form>
                      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#fa130d', textAlign: 'center' }}>
                        Contáctanos
                      </Typography>
                      <Typography variant="body1" gutterBottom sx={{ marginBottom: 4, color: colorText, textAlign: 'center' }}>
                        ¡Estamos aquí para ayudarte! Por favor, completa el siguiente formulario y nos pondremos en contacto contigo lo antes posible.
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field as={TextField} label="Nombre" name="nombre" fullWidth required error={touched.nombre && !!errors.nombre} helperText={touched.nombre && errors.nombre}
                            InputLabelProps={{ style: { color: 'white' } }}
                            InputProps={{ style: { color: 'white', borderColor: 'white' }, sx: { '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } } }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field name="telefono">
                            {({ field }) => (
                              <PhoneInput
                                country={'es'}
                                value={field.value}
                                placeholder='+34 --- --- ---'
                                onChange={(value) => setFieldValue('telefono', value)}
                                containerStyle={{ width: '100%', height: '56px' }}
                                inputStyle={{ width: '100%', height: '56px', color: 'white', borderColor: 'white', backgroundColor: 'transparent' }}
                                specialLabel="Teléfono"
                                inputProps={{
                                  required: true,
                                  name: 'telefono',
                                  error: touched.telefono && !!errors.telefono,
                                  helperText: touched.telefono && errors.telefono,
                                }}
                                enableSearch
                                countryCodeEditable={false}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth required error={touched.tipoEvento && !!errors.tipoEvento} sx={{ '& .MuiInputLabel-root': { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'white' }, '&.Mui-focused fieldset': { borderColor: 'white' } }, '& .MuiSelect-select': { color: 'white' } }}>
                            <InputLabel>Tipo de Evento</InputLabel>
                            <Field as={Select} name="tipoEvento" label="Tipo de Evento *">
                              <MenuItem value="Cumpleaños">Cumpleaños</MenuItem>
                              <MenuItem value="Corporativo">Corporativo</MenuItem>
                              <MenuItem value="Otro">Otro</MenuItem>
                            </Field>
                            {touched.tipoEvento && errors.tipoEvento && <Typography color="error">{errors.tipoEvento}</Typography>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePicker
                            label="Fecha del Evento"
                            value={values.fechaEvento}
                            sx={{ width: '100%', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'white' }, '&.Mui-focused fieldset': { borderColor: 'white' } }, '& .MuiInputBase-input': { color: 'white' }, '& .MuiInputLabel-root': { color: 'white' } }}
                            onChange={(value) => setFieldValue('fechaEvento', value)}
                            slotProps={{ textField: { variant: 'outlined', required: true, error: touched.fechaEvento && !!errors.fechaEvento, helperText: touched.fechaEvento && errors.fechaEvento } }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field as={TextField}
                            label="Número de Invitados"
                            name="numeroInvitados"
                            fullWidth
                            required
                            error={touched.numeroInvitados && !!errors.numeroInvitados}
                            helperText={touched.numeroInvitados && errors.numeroInvitados}
                            InputLabelProps={{ style: { color: 'white' } }}
                            InputProps={{ style: { color: 'white', borderColor: 'white' }, sx: { '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field as={TextField}
                            label="Mensaje"
                            name="mensaje"
                            multiline
                            rows={4}
                            fullWidth
                            error={touched.mensaje && !!errors.mensaje}
                            helperText={touched.mensaje && errors.mensaje}
                            InputLabelProps={{ style: { color: 'white' } }}
                            InputProps={{ style: { color: 'white', borderColor: 'white' }, sx: { '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } } }}
                          />
                        </Grid>
                        <Grid item xs={12} display={'flex'} alignContent={'center'} justifyContent={'center'}>
                          <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting || !isValid} sx={{
                            width: isMobileOrTablet ? '100%' : '25%',
                            backgroundColor: '#fa130d',
                            '&:hover': {
                              backgroundColor: '#c50e0d',
                            },
                          }}>
                            Enviar
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </LocalizationProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactForm;
